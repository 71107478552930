import React from 'react'
import { Icon } from 'semantic-ui-react'

const ChairDisplay = ({ imgLink, model, fileLink }) => {
  return (
    <div className="w-100 bg-light">
      <div className="row d-flex justify-content-center align-items-center py-4 px-5">
        <div className="col-md-3 py-4 animated bounceInLeft slow">
          <img src={imgLink} alt="novo chair" className="img-fluid" />
        </div>
        <div className="col-md-1" />
        <div className="col-md-4 d-flex flex-column justify-content-center py-4 animated bounceInRight slow">
          <h1 className="w-100 text-center">{model}</h1>
          <a href={fileLink} download className="action-button px-5 py-2 rounded-pill text-center">
            <Icon name="download" /> User Manual
          </a>
        </div>
      </div>
    </div>
  )
}
export default ChairDisplay
