import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'

import headerStyle from '../styles/navbar.module.scss'

const UserManualsNavBar = () => (
  <>
    <div className="container-fluid px-0 my-4">
      <h1 className="text-center pt-4">USER MANUALS</h1>
      <ul
        className={classNames(
          headerStyle.navBar,
          'd-flex flex-wrap  align-items-center justify-content-center'
        )}
      >
        <li className={classNames(headerStyle.navBarItem, 'mb-4')}>
          {' '}
          <Link
            to="/user-manuals/novo"
            activeClassName={headerStyle.activeNavBarItem}
            className={headerStyle.navBarLink}
          >
            NOVO
          </Link>
        </li>
        <li className={classNames(headerStyle.navBarItem, 'mb-4')}>
          <Link
            to="/user-manuals/tergo"
            activeClassName={headerStyle.activeNavBarItem}
            className={headerStyle.navBarLink}
          >
            TERGO
          </Link>
        </li>
        <li className={classNames(headerStyle.navBarItem, 'mb-4')}>
          <Link
            to="/user-manuals/magna"
            activeClassName={headerStyle.activeNavBarItem}
            className={headerStyle.navBarLink}
          >
            MAGNA
          </Link>
        </li>
        <li className={classNames(headerStyle.navBarItem, 'mb-4')}>
          <Link
            to="/user-manuals/v-sport"
            activeClassName={headerStyle.activeNavBarItem}
            className={headerStyle.navBarLink}
          >
            V-SPORT
          </Link>
        </li>
        <li className={classNames(headerStyle.navBarItem, 'mb-4')}>
          <Link
            to="/user-manuals/vip"
            activeClassName={headerStyle.activeNavBarItem}
            className={headerStyle.navBarLink}
          >
            VIP
          </Link>
        </li>
      </ul>
    </div>
  </>
)

export default UserManualsNavBar
